import { FC } from "react";
import { useController } from "./controller";
import {
  Form,
  Icons,
  List,
  Radio,
  Space,
  Table,
  Tag,
  TextField,
  getDefaultSortOrder,
} from "@pankod/refine-antd";
import { useNavigation, useTranslate } from "@pankod/refine-core";
import { ButtonTooltip, EmptyData, LinkText, SearchBox } from "components";
import { PATH } from "configs/path";
import usePermissions from "hooks/permission";
import {
  formatISODateTimeToView,
  getSlugToRedirectWorkId,
} from "utils/commons";
import CTableColumn from "components/CTableColumn";
import { DATE_TIME_FORMAT } from "configs/constants";
import { IQuotationRow, QuotationStatus } from "interfaces/Quotation";
import { getStatus } from "../show";

const Column = CTableColumn<IQuotationRow>();

const QuotationList: FC = () => {
  const {
    table: {
      tableQueryResult: { data: dataTable, isLoading },
      sorter,
      pageSize,
      current,
      setSorter,
      setPageSize,
      setCurrent,
      setFilters,
    },
  } = useController();
  const { edit, show, create } = useNavigation();
  const { checkCreateHaveHelperText, checkEditHaveHelperText } =
    usePermissions();
  const translate = useTranslate();

  return (
    <>
      <List
        title={translate("Danh sách báo giá")}
        headerButtons={
          <ButtonTooltip
            type="primary"
            icon={<Icons.PlusOutlined />}
            onClick={() => create(PATH.quotations)}
            {...checkCreateHaveHelperText(PATH.quotations)}
          >
            {translate("actions.create")}
          </ButtonTooltip>
        }
        breadcrumb={null}
      >
        <div className="list-content">
          <Form>
            <Form.Item name="search" style={{ marginBottom: 0 }}>
              <SearchBox
                placeholder={translate(
                  "Tìm kiếm mã vụ việc, mã PAKD, tên báo giá"
                )}
                onSearch={(e) => {
                  setCurrent(1);
                  setFilters([
                    {
                      field: "q",
                      operator: "eq",
                      value: e,
                    },
                  ]);
                }}
              />
            </Form.Item>
            <Form.Item name={["status"]} style={{ marginBottom: 16 }}>
              <Radio.Group
                defaultValue={-1}
                buttonStyle="solid"
                onChange={(e) => {
                  setCurrent(1);
                  setFilters([
                    {
                      field: "status",
                      operator: "eq",
                      value: e?.target?.value === -1 ? "" : e?.target?.value,
                    },
                  ]);
                }}
              >
                <Radio.Button value={-1}>
                  {translate("common.all")}
                </Radio.Button>
                <Radio.Button value={QuotationStatus.Unexported}>
                  {translate(`quotations.status.${QuotationStatus.Unexported}`)}
                </Radio.Button>
                <Radio.Button value={QuotationStatus.Exported}>
                  {translate(`quotations.status.${QuotationStatus.Exported}`)}
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Form>
          <Table
            sticky
            loading={isLoading}
            dataSource={dataTable?.data}
            rowKey="id"
            locale={{
              emptyText: <EmptyData text={translate("common.noDataSearch")} />,
            }}
            onRow={(record) => {
              return {
                onClick: () => {
                  show(PATH.quotations, record.id);
                },
              };
            }}
            pagination={{
              pageSize: pageSize,
              current: current,
              total: dataTable?.total,
              onChange: (cur, size) => {
                setCurrent(cur);
                setPageSize(size);
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0;
              },
              locale: {
                items_per_page: translate("common.pageNumber"),
              },
              showTotal: (total) => (
                <p>{translate("common.totalPage", { total: total })}</p>
              ),
            }}
            onChange={(_, __, s) => {
              const newSort = s as any;
              setSorter([
                {
                  field:
                    newSort?.field === "department"
                      ? "department.name"
                      : newSort?.field === "title"
                      ? "title.name"
                      : newSort?.field,
                  order: newSort?.order,
                },
              ]);
            }}
            scroll={{ x: "2000px" }}
            showSorterTooltip={false}
          >
            <Column
              key="code"
              dataIndex="code"
              title={translate("Mã báo giá")}
              render={(value, record) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder("code", sorter)}
              sorter
            />
            <Column
              key="name"
              dataIndex="name"
              title={translate("Tên báo giá")}
              render={(value, record) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder("name", sorter)}
              sorter
            />
            <Column
              key="quotationStatus"
              dataIndex="quotationStatus"
              title={translate("Trạng thái")}
              render={(value) => (
                <Tag {...getStatus(value)}>
                  {translate(`quotations.status.${value}`)}
                </Tag>
              )}
              defaultSortOrder={getDefaultSortOrder("status", sorter)}
              sorter
            />

            <Column
              key="case"
              dataIndex="case"
              title={translate("Vụ việc")}
              ellipsis
              render={(value) => <TextField value={value?.code} />}
              sorter
            />
            <Column
              key="businessPlan"
              dataIndex="businessPlan"
              title={translate("Mã PAKD")}
              ellipsis
              render={(value, record) => <TextField value={value?.code} />}
              sorter
            />

            <Column
              key="updatedName"
              dataIndex="updatedName"
              title={translate("common.updatedName")}
              render={(value) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder("updatedName", sorter)}
              sorter
            />

            <Column
              key="updatedDate"
              dataIndex="updatedDate"
              title={translate("common.updatedDate")}
              render={(value) => (
                <TextField
                  value={formatISODateTimeToView(value, DATE_TIME_FORMAT)}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("updatedDate", sorter)}
              sorter
            />

            <Column
              key="createdName"
              dataIndex="createdName"
              title={translate("common.createdName")}
              render={(value) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder("createdName", sorter)}
              sorter
            />

            <Column
              key="createdDate"
              dataIndex="createdDate"
              title={translate("common.createdDate")}
              render={(value) => (
                <TextField
                  value={formatISODateTimeToView(value, DATE_TIME_FORMAT)}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("createdDate", sorter)}
              sorter
            />

            <Column
              title={translate("table.actions")}
              dataIndex="actions"
              align="center"
              fixed="right"
              width={120}
              render={(_, record) => {
                return (
                  <ButtonTooltip
                    type="text"
                    icon={<Icons.EditOutlined />}
                    className="gray"
                    onClick={(e) => {
                      e.stopPropagation();
                      edit(PATH.quotations, record.id!);
                    }}
                    {...checkEditHaveHelperText(PATH.quotations)}
                  />
                );
              }}
            />
          </Table>
        </div>
      </List>
    </>
  );
};

export default QuotationList;
