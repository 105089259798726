import { FC } from "react";
import { useController } from "./controller";
import {
  Form,
  Icons,
  List,
  Radio,
  Space,
  Table,
  Tag,
  TextField,
  getDefaultSortOrder,
} from "@pankod/refine-antd";
import { useNavigation, useTranslate } from "@pankod/refine-core";
import { ButtonTooltip, DataText, EmptyData, SearchBox } from "components";
import { PATH } from "configs/path";
import usePermissions from "hooks/permission";
import { formatISODateTimeToView } from "utils/commons";
import CTableColumn from "components/CTableColumn";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "configs/constants";
import { Currency } from "components/Currency";
import Project from "interfaces/Project";
import { getStatus } from "../show";

const Column = CTableColumn<Project>();

const ProjectList: FC = () => {
  const {
    formProps,
    table: {
      tableQueryResult: { data: dataTable, isLoading },
      sorter,
      pageSize,
      current,
      setSorter,
      setPageSize,
      setCurrent,
      setFilters,
    },
    status,
  } = useController();
  const { edit, show, create } = useNavigation();
  const { checkCreateHaveHelperText, checkEditHaveHelperText } =
    usePermissions();
  const translate = useTranslate();

  return (
    <>
      <List
        title={translate("Dự án")}
        headerButtons={
          <ButtonTooltip
            type="primary"
            icon={<Icons.PlusOutlined />}
            onClick={() => create(PATH.projects)}
            {...checkCreateHaveHelperText(PATH.projects)}
          >
            {translate("actions.create")}
          </ButtonTooltip>
        }
        breadcrumb={null}
      >
        <div className="list-content">
          <Form {...formProps}>
            <SearchBox
              style={{ width: 280 }}
              placeholder={translate("Tìm kiếm mã vụ việc, mã dự án")}
              onSearch={(e) => {
                setCurrent(1);
                setFilters([
                  {
                    field: "q",
                    operator: "eq",
                    value: e,
                  },
                ]);
              }}
            />
            <Form.Item name={["projectStatusId"]} style={{ marginBottom: 16 }}>
              <Radio.Group
                defaultValue={-1}
                buttonStyle="solid"
                onChange={(e) => {
                  setCurrent(1);
                  setFilters([
                    {
                      field: "projectStatusId",
                      operator: "eq",
                      value: e?.target?.value === -1 ? "" : e?.target?.value,
                    },
                  ]);
                }}
              >
                <Radio.Button value={-1}>
                  {translate("common.all")}
                </Radio.Button>
                {status?.map(({ label, value }) => {
                  return (
                    <Radio.Button key={value} value={value}>
                      {label}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            </Form.Item>
          </Form>
          <Table
            sticky
            loading={isLoading}
            dataSource={dataTable?.data}
            rowKey="id"
            locale={{
              emptyText: <EmptyData text={translate("common.noDataSearch")} />,
            }}
            onRow={(record) => {
              return {
                onClick: () => {
                  show(PATH.projects, record.id);
                },
              };
            }}
            pagination={{
              pageSize: pageSize,
              current: current,
              total: dataTable?.total,
              onChange: (cur, size) => {
                setCurrent(cur);
                setPageSize(size);
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0;
              },
              locale: {
                items_per_page: translate("common.pageNumber"),
              },
              showTotal: (total) => (
                <p>{translate("common.totalPage", { total: total })}</p>
              ),
            }}
            onChange={(_, __, s) => {
              const newSort = s as any;
              setSorter([
                {
                  field:
                    newSort?.field === "department"
                      ? "department.name"
                      : newSort?.field === "title"
                      ? "title.name"
                      : newSort?.field,
                  order: newSort?.order,
                },
              ]);
            }}
            scroll={{ x: 2200 }}
            showSorterTooltip={false}
          >
            <Column
              key="id"
              dataIndex="id"
              title={translate("common.no")}
              render={(_, __, index) => (current - 1) * pageSize + index + 1}
              width={80}
            />

            <Column
              key="code"
              dataIndex="code"
              title={translate("Mã dự án")}
              render={(value, record) => <DataText value={value} />}
              width={150}
              defaultSortOrder={getDefaultSortOrder("code", sorter)}
              sorter
            />
            <Column
              key="case"
              dataIndex="case"
              title={translate("Vụ việc")}
              render={(value, record) => (
                <DataText value={record?.case?.name} className="primary" />
              )}
              width={200}
              defaultSortOrder={getDefaultSortOrder("case", sorter)}
              sorter
            />
            <Column
              key="totalProjectValue"
              dataIndex="totalProjectValue"
              title={translate("Giá trị")}
              render={(value, record) => <Currency value={value} />}
              width={200}
              align="right"
              defaultSortOrder={getDefaultSortOrder(
                "totalProjectValue",
                sorter
              )}
              sorter
            />

            <Column
              key="projectStatus"
              dataIndex="projectStatus"
              title={translate("Trạng thái")}
              render={(value, record) => (
                <Tag {...getStatus(record?.projectStatus?.code)}>
                  {record?.projectStatus?.name}
                </Tag>
              )}
              width={150}
              defaultSortOrder={getDefaultSortOrder("projectStatus", sorter)}
              sorter
            />

            <Column
              key="startDate"
              dataIndex="startDate"
              title={translate("Ngày bắt đầu")}
              render={(value) => (
                <TextField
                  value={formatISODateTimeToView(value, DATE_FORMAT)}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("startDate", sorter)}
              sorter
              width={150}
            />

            <Column
              key="endDate"
              dataIndex="endDate"
              title={translate("Ngày kết thúc")}
              render={(value) => (
                <TextField
                  value={formatISODateTimeToView(value, DATE_FORMAT)}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("endDate", sorter)}
              sorter
              width={150}
            />

            <Column
              key="updatedName"
              dataIndex="updatedName"
              title={translate("common.updatedName")}
              render={(value) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder("updatedName", sorter)}
              sorter
              width={200}
            />

            <Column
              key="updatedDate"
              dataIndex="updatedDate"
              title={translate("common.updatedDate")}
              render={(value) => (
                <TextField
                  value={formatISODateTimeToView(value, DATE_TIME_FORMAT)}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("updatedDate", sorter)}
              sorter
              width={200}
            />

            <Column
              key="createdName"
              dataIndex="createdName"
              title={translate("common.createdName")}
              render={(value) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder("createdName", sorter)}
              sorter
              width={200}
            />

            <Column
              key="createdDate"
              dataIndex="createdDate"
              title={translate("common.createdDate")}
              render={(value) => (
                <TextField
                  value={formatISODateTimeToView(value, DATE_TIME_FORMAT)}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("createdDate", sorter)}
              sorter
              width={200}
            />

            <Column
              title={translate("table.actions")}
              dataIndex="actions"
              align="center"
              fixed="right"
              width={120}
              render={(_, record) => {
                return (
                  <Space>
                    <ButtonTooltip
                      type="text"
                      icon={<Icons.EditOutlined />}
                      className="gray"
                      onClick={(e) => {
                        e.stopPropagation();
                        edit(PATH.projects, record.id!);
                      }}
                      {...checkEditHaveHelperText(PATH.projects)}
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </div>
      </List>
    </>
  );
};

export default ProjectList;
