import {
  Button,
  Col,
  Form,
  Icons,
  List,
  Radio,
  Row,
  Select,
  Table,
  Tag,
  TextField,
  getDefaultSortOrder,
} from "@pankod/refine-antd";
import { useNavigation, useTranslate } from "@pankod/refine-core";
import { ButtonTooltip, EmptyData, LinkText, SearchBox } from "components";
import CTableColumn from "components/CTableColumn";
import { Currency } from "components/Currency";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "configs/constants";
import { API_PATH, PATH, SLUGS } from "configs/path";
import usePermissions from "hooks/permission";
import IContract from "interfaces/Contract";
import { OptionViewApproval } from "interfaces/common";
import { FC } from "react";
import styled from "styled-components";
import { formatISODateTimeToView, searchSelect } from "utils/commons";
import { getStatus } from "../show";
import { useController } from "./controller";

const Column = CTableColumn<IContract>();

const RowStyle = styled(Row)`
  justify-content: flex-start;
  margin-bottom: 16px;

  @media only screen and (min-width: 1200px) {
    justify-content: flex-end;
  }
`;

const ContractList: FC = () => {
  const {
    formProps,
    form,
    table: {
      tableQueryResult: { data: dataTable, isLoading },
      sorter,
      pageSize,
      current,
      filters,
      setSorter,
      setPageSize,
      setCurrent,
      setFilters,
    },
    contractTypeProps,
    statusProps,
    customerFilterProps,
    supplierFilterProps,
    optionViewApproval,
    onChangeOptionView,
  } = useController();
  const { edit, show, create } = useNavigation();
  const { checkCreateHaveHelperText, checkEditHaveHelperText } =
    usePermissions();
  const translate = useTranslate();

  return (
    <>
      <List
        title={translate("Danh sách hợp đồng")}
        headerButtons={
          <ButtonTooltip
            type="primary"
            icon={<Icons.PlusOutlined />}
            onClick={() => create(PATH.contracts)}
            {...checkCreateHaveHelperText(PATH.contracts)}
          >
            {translate("actions.create")}
          </ButtonTooltip>
        }
        breadcrumb={null}
      >
        <div className="list-content">
          <Form {...formProps}>
            <Row gutter={[16, 16]}>
              <Col lg={24} xl={18}>
                <div style={{ display: "flex", gap: 8, flexWrap: "wrap" }}>
                  <SearchBox
                    placeholder={translate("Tìm kiếm sô hợp đồng, mã vụ việc")}
                    style={{ width: 300 }}
                    onSearch={(e) => {
                      setFilters([
                        {
                          field: "q",
                          operator: "eq",
                          value: e,
                        },
                      ]);
                    }}
                  />
                  <Form.Item name={["type"]} className="m-0">
                    <Select
                      {...contractTypeProps}
                      allowClear
                      onChange={(e) => {
                        setFilters([
                          {
                            field: "type",
                            operator: "eq",
                            value: e,
                          },
                        ]);
                      }}
                      className={"placeholderBlack"}
                      dropdownStyle={{ color: "black" }}
                      style={{ minWidth: 150 }}
                      bordered={false}
                      dropdownMatchSelectWidth={false}
                      placeholder={translate("Loại hợp đồng")}
                      filterOption={(inputValue: string, option: any) =>
                        option && searchSelect(inputValue, option?.label)
                      }
                    />
                  </Form.Item>
                  <Form.Item name={["customerId"]} className="m-0">
                    <Select
                      {...customerFilterProps}
                      allowClear
                      onChange={(e) => {
                        setFilters([
                          {
                            field: "customerId",
                            operator: "eq",
                            value: e,
                          },
                        ]);
                      }}
                      className={"placeholderBlack"}
                      dropdownStyle={{ color: "black" }}
                      style={{ minWidth: 150 }}
                      bordered={false}
                      dropdownMatchSelectWidth={false}
                      placeholder={translate("Khách hàng")}
                      filterOption={(inputValue: string, option: any) =>
                        option && searchSelect(inputValue, option?.label)
                      }
                    />
                  </Form.Item>
                  <Form.Item name={["supplierId"]} className="m-0">
                    <Select
                      {...supplierFilterProps}
                      allowClear
                      onChange={(e) => {
                        setFilters([
                          {
                            field: "supplierId",
                            operator: "eq",
                            value: e,
                          },
                        ]);
                      }}
                      className={"placeholderBlack"}
                      dropdownStyle={{ color: "black" }}
                      style={{ minWidth: 150 }}
                      bordered={false}
                      dropdownMatchSelectWidth={false}
                      placeholder={translate("Nhà cung cấp")}
                      filterOption={(inputValue: string, option: any) =>
                        option && searchSelect(inputValue, option?.label)
                      }
                    />
                  </Form.Item>

                  {filters &&
                    filters.length > 0 &&
                    !(
                      filters.length === 1 &&
                      filters[0]?.operator === "containss"
                    ) && (
                      <Button
                        type="text"
                        onClick={(e) => {
                          form.resetFields();
                          setFilters([], "replace");
                        }}
                        style={{ color: "#8C8C8C" }}
                      >
                        {translate("common.cleanFilter")}
                        <Icons.CloseOutlined />
                      </Button>
                    )}
                </div>
              </Col>
              <Form.Item name={["optionViewApproval"]} hidden />
              <Col lg={24} xl={6}>
                <RowStyle gutter={8}>
                  <Col>
                    <Button
                      onClick={onChangeOptionView(OptionViewApproval.MINE)}
                      shape="round"
                      type={
                        optionViewApproval === OptionViewApproval.MINE
                          ? "primary"
                          : "ghost"
                      }
                    >
                      Của tôi
                    </Button>
                  </Col>
                </RowStyle>
              </Col>
            </Row>
            <Form.Item name={["status"]} style={{ marginBottom: 16 }}>
              <Radio.Group
                defaultValue={-1}
                buttonStyle="solid"
                onChange={(e) => {
                  setCurrent(1);
                  setFilters([
                    {
                      field: "status",
                      operator: "eq",
                      value: e?.target?.value === -1 ? "" : e?.target?.value,
                    },
                  ]);
                }}
              >
                <Radio.Button value={-1}>
                  {translate("common.all")}
                </Radio.Button>
                {statusProps?.options?.map(({ label, value }) => {
                  return (
                    <Radio.Button value={value}>
                      {translate(label as string)}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            </Form.Item>
          </Form>
          <Table
            sticky
            loading={isLoading}
            dataSource={dataTable?.data}
            rowKey="id"
            locale={{
              emptyText: <EmptyData text={translate("common.noDataSearch")} />,
            }}
            onRow={(record) => {
              return {
                onClick: () => {
                  show(PATH.contracts, record.id);
                },
              };
            }}
            pagination={{
              pageSize: pageSize,
              current: current,
              total: dataTable?.total,
              showSizeChanger: true,
              onChange: (cur, size) => {
                setCurrent(cur);
                setPageSize(size);
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0;
              },
              locale: {
                items_per_page: translate("common.pageNumber"),
              },
              showTotal: (total) => (
                <p>{translate("common.totalPage", { total: total })}</p>
              ),
            }}
            onChange={(_, __, s) => {
              const newSort = s as any;
              setSorter([
                {
                  field:
                    newSort?.field === "department"
                      ? "department.name"
                      : newSort?.field === "title"
                      ? "title.name"
                      : newSort?.field,
                  order: newSort?.order,
                },
              ]);
            }}
            scroll={{ x: 2850 }}
            showSorterTooltip={false}
          >
            <Column
              key="contractCode"
              dataIndex="contractCode"
              width={200}
              title={translate("Mã hợp đồng")}
              render={(value, record) => (
                <TextField
                  value={value || "-"}
                  className={value && "primary"}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("contractCode", sorter)}
              sorter
            />

            <Column
              key="code"
              dataIndex="code"
              width={200}
              title={translate("Số hợp đồng")}
              render={(value, record) => <TextField value={value || "-"} />}
              defaultSortOrder={getDefaultSortOrder("code", sorter)}
              sorter
            />

            <Column
              key="customerContractCode"
              dataIndex="customerContractCode"
              width={200}
              title={translate("Số hợp đồng của KH")}
              render={(value, record) => <TextField value={value || "-"} />}
              defaultSortOrder={getDefaultSortOrder(
                "customerContractCode",
                sorter
              )}
              sorter
            />

            <Column
              key="case"
              dataIndex="case"
              width={150}
              title={translate("Vụ việc")}
              render={(value, record) => (
                <LinkText
                  value={{ id: record?.case?.value, code: record?.case?.code }}
                  resource={SLUGS.caseForSale}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("case", sorter)}
              sorter
            />

            <Column
              key="case"
              dataIndex="case"
              width={200}
              title={translate("Tên vụ việc")}
              render={(value, record) => (
                <TextField value={record?.case?.label} />
              )}
              defaultSortOrder={getDefaultSortOrder("case", sorter)}
              sorter
            />

            <Column
              key="name"
              dataIndex="name"
              width={200}
              title={translate("Tên hợp đồng")}
              render={(value, record) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder("name", sorter)}
              sorter
            />

            <Column
              key="contractType"
              dataIndex="contractType"
              width={200}
              title={translate("Loại hợp đồng")}
              render={(value, record) => (
                <TextField value={record?.contractType?.name} />
              )}
              defaultSortOrder={getDefaultSortOrder("contractType", sorter)}
              sorter
            />

            <Column
              key="originalContract"
              dataIndex="originalContract"
              width={200}
              title={translate("Hợp đồng gốc")}
              render={(value, record) => (
                <LinkText
                  value={{
                    id: record?.originalContract?.id,
                    code:
                      record?.originalContract?.code ??
                      record?.originalContract?.name,
                  }}
                  resource={SLUGS.contract}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("code", sorter)}
              sorter
            />

            <Column
              key="contractStatus"
              dataIndex="contractStatus"
              width={200}
              title={translate("Trạng thái")}
              render={(value, record) => (
                <Tag {...getStatus(record?.contractStatus?.code)}>
                  {record?.contractStatus?.name}
                </Tag>
              )}
              defaultSortOrder={getDefaultSortOrder("contractStatus", sorter)}
              sorter
            />

            <Column
              key="revenueBeforeTax"
              dataIndex="revenueBeforeTax"
              width={200}
              align="right"
              title={translate("Doanh số trước thuế")}
              render={(value, record) => (
                <Currency
                  value={value}
                  customStyles={{ whiteSpace: "nowrap" }}
                  showCurrency={false}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("revenueBeforeTax", sorter)}
              sorter
            />

            <Column
              key="validDate"
              dataIndex="validDate"
              width={200}
              title={translate("Ngày hiệu lực")}
              render={(value, record) => (
                <TextField
                  value={formatISODateTimeToView(value, DATE_FORMAT)}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("validDate", sorter)}
              sorter
            />

            <Column
              key="expirationDate"
              dataIndex="expirationDate"
              width={200}
              title={translate("Ngày hết hiệu lực")}
              render={(value, record) => (
                <TextField
                  value={formatISODateTimeToView(value, DATE_FORMAT)}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("expirationDate", sorter)}
              sorter
            />

            <Column
              key="updatedName"
              dataIndex="updatedName"
              width={200}
              title={translate("common.updatedName")}
              render={(value) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder("updatedName", sorter)}
              sorter
            />

            <Column
              key="updatedDate"
              dataIndex="updatedDate"
              width={200}
              title={translate("common.updatedDate")}
              render={(value) => (
                <TextField
                  value={formatISODateTimeToView(value, DATE_TIME_FORMAT)}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("updatedDate", sorter)}
              sorter
            />

            <Column
              key="createdName"
              dataIndex="createdName"
              width={200}
              title={translate("common.createdName")}
              render={(value) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder("createdName", sorter)}
              sorter
            />

            <Column
              key="createdDate"
              dataIndex="createdDate"
              width={200}
              title={translate("common.createdDate")}
              render={(value) => (
                <TextField
                  value={formatISODateTimeToView(value, DATE_TIME_FORMAT)}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("createdDate", sorter)}
              sorter
            />

            <Column
              title={translate("table.actions")}
              dataIndex="actions"
              align="center"
              fixed="right"
              width={120}
              render={(_, record) => {
                return (
                  <ButtonTooltip
                    type="text"
                    icon={<Icons.EditOutlined />}
                    className="gray"
                    onClick={(e) => {
                      e.stopPropagation();
                      edit(PATH.contracts, record.id!);
                    }}
                    {...checkEditHaveHelperText(PATH.contracts)}
                  />
                );
              }}
            />
          </Table>
        </div>
      </List>
    </>
  );
};

export default ContractList;
