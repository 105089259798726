import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import { ApprovalPage } from "components/Approval";
import { PATH, SLUGS } from "configs/path";
import { IPayment } from "interfaces";
import React from "react";
import { Information } from "./components/Information";
import { ApprovalServiceRequest } from "components/ApprovalServiceRequest";
import { Card, Typography } from "@pankod/refine-antd";
const { Text } = Typography;
interface PaymentShowProps {
  isApprover?: boolean;
}

export const PaymentShow: React.FC<
  IResourceComponentsProps & PaymentShowProps
> = (props) => {
  const t = useTranslate();
  return (
    <ApprovalServiceRequest
      cloneResource={true}
      {...props}
      title={(record: any) => { 
        return <>
          {t("payments.titles.list")}
          <Text className="primary">{`: ${record?.objectData?.case?.name}`} </Text>
        </>
      }}
      onApprovalSuccess={() => { }}
      resource={PATH.payments}
      renderInformation={(record: any) =>
        <Card style={{ background: "#ffffff" }}>
          <Information record={record?.objectData} />
        </Card>
      }
    />
  );
};
