import { Form, Input, Typography, notification } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { useFormRule } from "hooks/useFormRule";
import { FC, memo } from "react";
import { IForm } from "../../..";
import { API_PATH } from "configs/path";
import UploadMulti from "components/UploadMulti";

const { Title } = Typography;
const { TextArea } = Input;
const { useWatch, useFormInstance } = Form;

const SettlementInfo: FC = memo(() => {
  const translate = useTranslate();
  const { required } = useFormRule();
  const form = useFormInstance<IForm>();
  const caseId = useWatch("caseId", form);

  const onClickButtonUpload = (action: () => void) => {
    if (!caseId) {
      notification.error({
        message: translate("Vui lòng chọn mã vụ việc trước khi tải file."),
      });
    } else {
      action();
    }
  };

  return (
    <div style={{ backgroundColor: "white", padding: 24 }}>
      <Title level={5}>{translate("Quyết toán triển khai")}</Title>
      <Form.Item
        label={translate("Nội dung quyết toán triển khai")}
        name="content"
        rules={[
          ...required,
          { max: 5000, message: translate("errors.ER014", { max: "5,000" }) },
        ]}
      >
        <TextArea rows={4} placeholder={translate("Nhập nội dung")} />
      </Form.Item>
      <Form.Item
        name="documents"
        validateStatus="success"
        label=""
        style={{ marginTop: 32 }}
        rules={required}
      >
        <UploadMulti
          required
          title={translate("File đính kèm")}
          url={`${process.env.REACT_APP_API_URL}${API_PATH.settlementUploadFile}?caseId=${caseId}`}
          onClickButtonUpload={onClickButtonUpload}
          idKey="id"
          pathPreviewFile={API_PATH.settlementPreviewFile}
        />
      </Form.Item>
    </div>
  );
});

export default SettlementInfo;
