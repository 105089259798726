import {
  Button,
  Col,
  Form,
  Icons,
  Input,
  Modal,
  Row,
  Space,
  Table,
  TextField,
  Typography,
  notification,
  useForm,
  getDefaultSortOrder,
} from "@pankod/refine-antd";
import {
  useCreate,
  useOne,
  useTable,
  useTranslate,
  useUpdate,
} from "@pankod/refine-core";
import { ButtonTooltip, DataText, SelectCustom } from "components";
import ButtonConfirm from "components/ButtonConfirm";
import { Currency } from "components/Currency";
import { DateTimeFieldCustom } from "components/DateTimeTextField";
import { v4 as uuid } from "uuid";
import InputMoney from "pages/sale/businessOpportunities/create/components/MoneyInput";
import {
  FC,
  memo,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { API_PATH, PATH } from "configs/path";
import usePermissions from "hooks/permission";
import { ContractShowConText } from "../..";
import { PAGE_SIZE_OPTIONS } from "configs/constants";
import { useParams } from "react-router";

interface IForm {
  commitmentStatusId: string;
  amountBeforeTax: number;
  amountAfterTax: number;
  note: string;
}
const { Text, Title } = Typography;
const { TextArea } = Input;

const { useWatch } = Form;
const ContractRecord: FC = memo(() => {
  const { id } = useParams();
  const record = useContext(ContractShowConText);
  const { checkEditHaveHelperText, checkCreateHaveHelperText } =
    usePermissions();
  const { form, formProps, saveButtonProps } = useForm<any, any, IForm>();
  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useCreate<any>();
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useUpdate<any>();

  const {
    tableQueryResult: { data: dataTable },
    current,
    pageSize,
    setCurrent,
    setPageSize,
    sorter,
    setSorter,
  } = useTable<any>({
    resource: PATH.contractCommitments,
    initialCurrent: 1,
    initialSorter: [
      {
        field: "createdDate",
        order: "desc",
      },
    ],
    initialFilter: [
      {
        field: "contractId",
        operator: "containss",
        value: id,
      },
    ],
    queryOptions: {
      enabled: !!record?.id,
    },
  });

  const isLoading = isLoadingCreate || isLoadingUpdate;

  const translate = useTranslate();

  const [rowSelected, setRowSelected] = useState<any>();

  const [visibleModalConfirmCreate, setVisibleModalConfirmCreate] =
    useState(false);

  const [visibleModalConfirmChangeRow, setVisibleModalConfirmChangeRow] =
    useState(false);

  const [isAdding, setIsAdding] = useState(false);

  const draftRowSelect = useRef<any>();

  const commitmentStatusId = useWatch("commitmentStatusId", form);
  const amountBeforeTax = useWatch("amountBeforeTax", form);
  const amountAfterTax = useWatch("amountAfterTax", form);
  const note = useWatch("note", form);

  const toggleModalConfirmCreate = () =>
    setVisibleModalConfirmCreate((prev) => !prev);

  const toggleModalConfirmChangeRow = () =>
    setVisibleModalConfirmChangeRow((prev) => !prev);

  const isChanged = useMemo(() => {
    return (
      rowSelected?.commitmentStatus?.id !== commitmentStatusId ||
      rowSelected?.amountBeforeTax !== amountBeforeTax ||
      rowSelected?.amountAfterTax !== amountAfterTax ||
      rowSelected?.note !== note
    );
  }, [commitmentStatusId, amountBeforeTax, amountAfterTax, note, rowSelected]);

  const onFinishedForm = () => {
    if (rowSelected && !isChanged) {
      setRowSelected(undefined);
    } else {
      toggleModalConfirmCreate();
    }
  };

  const isAddingRow = useCallback(
    (rowIndex: number) => {
      return rowIndex === 0 && isAdding;
    },
    [isAdding]
  );

  const onAdd = () => {
    form.resetFields();
    setRowSelected(undefined);
    setIsAdding(true);
  };
  const {data: currentAM} = useOne({
    resource: API_PATH.contractGetCurrentAM(record?.caseId!),
    id: "",
    queryOptions: {
      enabled: record?.caseId ? true: false,
    },
  });
  
  const isEditingRow = useCallback(
    (row?: any) => row?.id === rowSelected?.id,
    [rowSelected]
  );

  const dataList = useMemo(() => {
    let temp: any[] = dataTable?.data || [];
    if (isAdding) {
      temp = [{ id: uuid(), isCreate: true }, ...temp];
    }

    return temp.map((t) => ({
      ...t,
      departmentId: t?.department?.id,
      commitmentStatusId: t?.commitmentStatus?.id,
    }));
  }, [isAdding, dataTable]);

  const onSubmit = () => {
    if (isAdding) {
      onCreate();
    } else {
      onEdit();
    }
  };

  const onCreate = () => {
    const dataForm = form.getFieldsValue();
    mutateCreate(
      {
        resource: PATH.contractCommitments,
        values: {
          ...dataForm,
          note: dataForm?.note?.trim(),
          contractId: record?.id,
          caseId: record?.caseId,
        },
      },
      {
        onSuccess(data, variables, context) {
          notification.success({ message: "Tạo mới thành công" });
          setIsAdding(false);
          toggleModalConfirmCreate();
        },
        onError(error, variables, context) {
          notification.error({ message: "Tạo mới thất bại" });
          toggleModalConfirmCreate();
        },
      }
    );
  };

  const onEdit = () => {
    const dataForm = form.getFieldsValue();

    mutateUpdate(
      {
        resource: PATH.contractCommitments,
        id: rowSelected?.id,
        values: {
          ...dataForm,
          note: dataForm?.note?.trim(),
        },
      },
      {
        onSuccess(data, variables, context) {
          notification.success({ message: "Chỉnh sửa thành công" });
          setRowSelected(undefined);
          toggleModalConfirmCreate();
        },
        onError(error, variables, context) {
          notification.error({ message: "Chỉnh sửa thất bại" });
          toggleModalConfirmCreate();
        },
      }
    );
  };

  return (
    <div style={{ background: "white", padding: 24 }}>
      <Row style={{ marginBottom: 16 }}>
        <Col flex={1}>
          <Title level={5}>{translate("Ghi nhận hợp đồng")}</Title>
        </Col>
        <div style={{ textAlign: "right" }}>
          <Button
            type="primary"
            onClick={onAdd}
            icon={<Icons.PlusOutlined />}
            {...checkCreateHaveHelperText(PATH.contractCommitments)}
          >
            {translate("actions.create")}
          </Button>
        </div>
      </Row>
      <Form {...formProps} onFinish={onFinishedForm}>
        <Table
          dataSource={dataList}
          rowKey="id"
          locale={{
            emptyText: " ",
          }}
          pagination={{
            pageSize: isAdding ? pageSize + 1 : pageSize,
            current: current,
            total: dataTable?.total,
            showSizeChanger: true,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            onShowSizeChange(_, size) {
              setIsAdding(false);
              setPageSize(size);
            },
            onChange: (cur) => {
              setCurrent(cur);
              document.body.scrollTop = 0; // For Safari
              document.documentElement.scrollTop = 0;
            },
            locale: {
              items_per_page: translate("common.pageNumber"),
            },
            showTotal: (total) => (
              <p>{translate("common.totalPage", { total: total })}</p>
            ),
          }}
          onChange={(_, __, s) => {
            const newSort = s as any;
            setSorter([
              {
                field: newSort?.field,
                order: newSort?.order,
              },
            ]);
          }}
          size="small"
          className="vertical-align-top"
          scroll={{ x: 1400 }}
        >
          <Table.Column
            key="id"
            dataIndex="id"
            title={translate("common.no")}
            width={60}
            align="center"
            render={(_, __, index) =>
              isAddingRow(index)
                ? "-"
                : (current - 1) * pageSize + index + (isAdding ? 0 : 1)
            }
          />
          <Table.Column
            dataIndex="amountBeforeTax"
            title={translate("Doanh số trước thuế")}
            width={200}
            sorter
            align="right"
            defaultSortOrder={getDefaultSortOrder("amountBeforeTax", sorter)}
            render={(value, record, index) => {
              if (isAddingRow(index) || isEditingRow(record)) {
                return (
                  <InputMoney
                    name="amountBeforeTax"
                    isLimit
                    isRequired
                    className="m-0"
                  />
                );
              }

              return <Currency value={value} showCurrency={false} />;
            }}
          />
          <Table.Column
            dataIndex="amountAfterTax"
            title={translate("Doanh số sau thuế")}
            width={200}
            sorter
            align="right"
            defaultSortOrder={getDefaultSortOrder("amountAfterTax", sorter)}
            render={(value, record, index) => {
              if (isAddingRow(index) || isEditingRow(record)) {
                return (
                  <InputMoney
                    name="amountAfterTax"
                    isLimit
                    isRequired
                    className="m-0"
                  />
                );
              }

              return <Currency value={value} showCurrency={false} />;
            }}
          />
          <Table.Column
            width={200}
            dataIndex={["currentAM", "name"]}
            title={translate("employees.business")}
            render={(value, record: any, index) => {
              const nameCurrentAM = isAddingRow(index)
                ? `${currentAM?.data?.code}-${currentAM?.data?.name}`
                : `${record.currentAM.code}-${record.currentAM.name}`;

              return <TextField className="primary" value={nameCurrentAM} />;
            }}
          />
          <Table.Column
            dataIndex="commitmentStatus"
            title={translate("Trạng thái")}
            width={150}
            sorter
            defaultSortOrder={getDefaultSortOrder("commitmentStatus", sorter)}
            render={(value, record: any, index) => {
              if (isAddingRow(index) || isEditingRow(record)) {
                return (
                  <Form.Item
                    name="commitmentStatusId"
                    rules={[
                      { required: true, message: translate("errors.ER005") },
                    ]}
                    className="m-0"
                  >
                    <SelectCustom
                      resource={API_PATH.contractCommitmentStatusDropdownlist}
                      optionLabel="name"
                      optionValue="id"
                      placeholder={translate("Chọn trạng thái")}
                      style={{ color: "#000" }}
                      allowClear
                    />
                  </Form.Item>
                );
              }

              return record?.commitmentStatus?.name;
            }}
          />
          <Table.Column
            dataIndex="note"
            title={translate("Ghi chú")}
            width={200}
            render={(value, record, index) => {
              if (isAddingRow(index) || isEditingRow(record)) {
                return (
                  <Form.Item
                    name="note"
                    rules={[
                      {
                        max: 3000,
                        message: translate("errors.ER014", { max: 3000 }),
                      },
                    ]}
                    className="m-0"
                  >
                    <TextArea autoSize={{ minRows: 1, maxRows: 4 }} />
                  </Form.Item>
                );
              }

              return <DataText value={value} />;
            }}
          />

          <Table.Column
            width={200}
            dataIndex="commitmentDate"
            title={translate("Ngày ghi nhận")}
            sorter
            defaultSortOrder={getDefaultSortOrder("commitmentDate", sorter)}
            render={(value, _, index) => {
              if (isAddingRow(index)) {
                return "-";
              }
              return value ? <DateTimeFieldCustom value={value} /> : "-";
            }}
          />

          <Table.Column
            width={200}
            dataIndex="updatedName"
            title={translate("common.updatedUsername")}
            render={(value, _, index) => {
              if (isAddingRow(index)) {
                return "-";
              }
              return <TextField className="primary" value={value} />;
            }}
          />
          <Table.Column
            width={200}
            dataIndex="updatedDate"
            title={translate("common.updatedDate")}
            sorter
            defaultSortOrder={getDefaultSortOrder("updatedDate", sorter)}
            render={(value, _, index) => {
              if (isAddingRow(index)) {
                return "-";
              }
              return value && <DateTimeFieldCustom value={value} />;
            }}
          />
          <Table.Column
            title={translate("table.actions")}
            dataIndex="actions"
            align="center"
            fixed="right"
            width={120}
            onCell={() => ({
              onClick: (e) => {
                e.stopPropagation();
              },
            })}
            render={(_, record: any, index) => {
              if (isAddingRow(index) || isEditingRow(record)) {
                return (
                  <Space className="btn-static">
                    <ButtonTooltip
                      type="text"
                      icon={<Icons.SaveOutlined />}
                      className="gray"
                      {...saveButtonProps}
                    />
                    <ButtonConfirm
                      danger
                      type="text"
                      icon={<Icons.CloseCircleOutlined />}
                      noCancel={false}
                      className="gray"
                      description="Sau khi hủy bỏ, mọi tháo tác của bạn sẽ không được ghi nhận!"
                      onClick={(e) =>
                        isAddingRow(index)
                          ? setIsAdding(false)
                          : setRowSelected(undefined)
                      }
                    />
                  </Space>
                );
              }
              return (
                <Space>
                  <ButtonConfirm
                    type="text"
                    icon={<Icons.EditOutlined />}
                    className="gray"
                    visible={
                      visibleModalConfirmChangeRow &&
                      draftRowSelect?.current?.id === record?.id
                    }
                    toggle={() => {
                      draftRowSelect.current = record;

                      if ((!rowSelected || !isChanged) && !isAdding) {
                        setIsAdding(false);
                        form.setFieldsValue({ ...record });
                        setRowSelected(record);
                        return;
                      } else {
                        toggleModalConfirmChangeRow();
                      }
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleModalConfirmChangeRow();
                      setIsAdding(false);
                      form.setFieldsValue({ ...record });
                      setRowSelected(record);
                    }}
                    danger
                    noCancel={false}
                    text={isAdding ? "Tạo mới" : "Chỉnh sửa"}
                    description="Bạn muốn hủy lưu dòng này!"
                    {...checkEditHaveHelperText(PATH.contractCommitments)}
                  />
                </Space>
              );
            }}
          />
        </Table>
      </Form>
      <Modal
        title=""
        visible={visibleModalConfirmCreate}
        onOk={onSubmit}
        onCancel={toggleModalConfirmCreate}
        okText={translate("buttons.confirm")}
        cancelText={translate("buttons.reject")}
        okButtonProps={{ loading: isLoading }}
        cancelButtonProps={{ disabled: isLoading }}
      >
        <Space align="start">
          <Text style={{ fontSize: 22 }} type="warning">
            <Icons.InfoCircleOutlined />
          </Text>
          <div>
            <Text style={{ fontWeight: 500, fontSize: 16 }}>
              {"Bạn muốn lưu dữ liệu ở dòng này?"}
            </Text>
          </div>
        </Space>
      </Modal>
    </div>
  );
});

export default ContractRecord;
