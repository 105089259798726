import {
  Button,
  Col,
  Collapse,
  Descriptions,
  Form,
  Icons,
  Input,
  Row,
  Select,
  Table,
  Typography,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import ButtonConfirm from "components/ButtonConfirm";
import { Currency } from "components/Currency";
import InputMoney from "pages/sale/businessOpportunities/create/components/MoneyInput";
import {
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { v4 as uuid } from "uuid";
import { cloneDeep } from "lodash";
import {
  IForm,
  SettlementFormContext,
} from "pages/projectManagement/settlements/create";
import { useFormRule } from "hooks/useFormRule";
import { isEmpty, searchSelect } from "utils/commons";
import { DataText } from "components";
import { RequiredLable } from "components/RequiredLabel";

const { useFormInstance, useWatch } = Form;

const { Text } = Typography;
const { Panel } = Collapse;
const { TextArea } = Input;

const CostByMoney: FC = memo(() => {
  const {
    costByMoneyKeyState,
    costSelect,
    dataForCreate,
    initialValues,
    calcTotal,
  } = useContext(SettlementFormContext);
  const form = useFormInstance<IForm>();

  const { required } = useFormRule();

  const listCostByMoney = useWatch("listCostByMoney", form);

  const translate = useTranslate();

  const [visibleCollapse, setVisibleCollapse] = useState(true);
  const [newCostMoneyUuid, setNewCostMoneyUuid] = useState<string[]>([]);
  const [list, setList] = costByMoneyKeyState;

  const dataTable = useMemo(() => {
      return [...list, ...newCostMoneyUuid];
  }, [list, newCostMoneyUuid]);

  const onRemoveCost = (index: number, uuid: string) => () => {
    setNewCostMoneyUuid(newCostMoneyUuid.filter((item) => item !== uuid));
    const newList = cloneDeep(list);
    newList.splice(index, 1);
    setList(newList);
  };

  const toggleCollapse = () => setVisibleCollapse((prev) => !prev);

  const isReadonly = useCallback(
    (keyRow: string) => !listCostByMoney?.[keyRow]?.editable,
    [listCostByMoney]
  );

  // const isPolicy = useCallback(
  //   (keyRow: string) =>
  //     !isEmpty(listCostByMoney?.[keyRow]?.proportion) && !isAllProducts,
  //   [listCostByMoney, isAllProducts]
  // );

  const convertListCostByMoney = useMemo(
    () => Object.values(listCostByMoney || {}),
    [listCostByMoney]
  );

  useEffect(() => {
    const a = document.getElementsByClassName("ant-row ant-row-middle");
    a[0]?.setAttribute("style", `margin-bottom: 0px`);
  });

  return (
    <Collapse
      className="w-full"
      defaultActiveKey={["1"]}
      ghost
      onChange={toggleCollapse}
      collapsible="header"
    >
      <Panel
        header={translate("settlements.cost.cash")}
        key="1"
        extra={
          visibleCollapse && (
            <Row align="middle">
              <Col flex={1} />
              <div style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    const uuidKey = uuid();
                    setNewCostMoneyUuid([...newCostMoneyUuid, uuidKey]);
                  }}
                  icon={<Icons.PlusOutlined />}
                  style={{ marginTop: 10 }}
                >
                  {translate("actions.create")}
                </Button>
              </div>
            </Row>
          )
        }
      >
        <Table<String>
          style={{ marginTop: 8 }}
          size="small"
          className="vertical-align-top w-full"
          dataSource={dataTable}
          pagination={{
            hideOnSinglePage: true,
            pageSize: 1000,
          }}
          locale={{
            emptyText: " ",
          }}
          scroll={{ x: 1000 }}
        >
          <Table.Column
            title={translate("common.no")}
            dataIndex="name"
            width={50}
            align="center"
            render={(v, keyRow: string, index) => {
              if (keyRow === "sum") return null;

              return <Text>{index + 1}</Text>;
            }}
          />
          <Table.Column
            title={translate("settlements.cash.costName")}
            dataIndex="id"
            width={200}
            render={(v, keyRow: string) => {
              return (
                <>
                  <Form.Item
                    hidden
                    name={["listCostByMoney", keyRow, "editable"]}
                    initialValue={true}
                  />
                  <Form.Item
                    hidden
                    name={["listCostByMoney", keyRow, "refId"]}
                  />
                  <Form.Item hidden name={["listCostByMoney", keyRow, "id"]} />
                  <Form.Item
                    hidden
                    name={["listCostByMoney", keyRow, "costName"]}
                  />
                  <Form.Item
                    required
                    className="m-0"
                    name={["listCostByMoney", keyRow, "costId"]}
                    rules={isReadonly(keyRow) ? [] : required}
                  >
                    {isReadonly(keyRow) ? (
                      <Text>{listCostByMoney?.[keyRow]?.costName || "-"}</Text>
                    ) : (
                      <Select
                        {...costSelect?.selectProps}
                        placeholder={translate("settlements.select.cost")}
                        dropdownMatchSelectWidth={false}
                        filterOption={(inputValue: string, option: any) => {
                          return (
                            option && searchSelect(inputValue, option?.label)
                          );
                        }}
                      />
                    )}
                  </Form.Item>
                </>
              );
            }}
          />
          <Table.Column
            title={<RequiredLable text={translate("settlements.value")} />}
            dataIndex="name"
            width={200}
            render={(v, keyRow: string) => {
              return (
                <InputMoney
                  className="m-0"
                  name={["listCostByMoney", keyRow, "settlementValue"]}
                  isRequired
                  style={{ margin: 0 }}
                  placeholder={translate("common.input.number")}
                  min={0}
                  errorCode="ER0204"
                />
              );
            }}
          />
          <Table.Column
            title={translate("settlements.estimate.value")}
            dataIndex="name"
            width={200}
            render={(v, keyRow: string) => {
              const readonly = isReadonly(keyRow);
              if (!readonly) return "-";

              return (
                <Form.Item
                  name={["listCostByMoney", keyRow, "estimatedValue"]}
                  className="m-0"
                >
                  <Currency
                    className="blue"
                    value={listCostByMoney?.[keyRow]?.estimatedValue}
                    showCurrency={false}
                  />
                </Form.Item>
              );
            }}
          />
          <Table.Column
            title={translate("settlements.cash.note")}
            dataIndex="unitPrice"
            width={200}
            render={(v, keyRow: string) => {
              if (keyRow === "sum") return null;
              const readonly = isReadonly(keyRow);
              return (
                <Form.Item
                  name={["listCostByMoney", keyRow, "note"]}
                  className="m-0"
                  rules={[
                    {
                      max: 2000,
                      message: translate("errors.ER014", { max: "2,000" }),
                    },
                  ]}
                >
                  {readonly ? (
                    <DataText
                      value={listCostByMoney?.[keyRow]?.note || "-"}
                    ></DataText>
                  ) : (
                    <TextArea
                      autoSize={{ minRows: 1, maxRows: 4 }}
                      placeholder={translate("common.input.information")}
                    />
                  )}
                </Form.Item>
              );
            }}
          />
          s
          <Table.Column
            align="center"
            fixed="right"
            width={90}
            title={translate("table.actions")}
            render={(uuid, keyRow: string, index) => {
              const readonly = isReadonly(keyRow);
              return (
                <ButtonConfirm
                  text={translate("settlements.action.deleteRatecard")}
                  danger
                  description=""
                  onClick={onRemoveCost(index, uuid)}
                  type="text"
                  hidden={readonly}
                >
                  <Icons.DeleteOutlined />
                </ButtonConfirm>
              );
            }}
          />
        </Table>

        <Descriptions
          style={{ marginTop: 4 }}
          layout="vertical"
          column={{ md: 2, xs: 1 }}
        >
          <Descriptions.Item
            labelStyle={{ fontWeight: 500 }}
            label={translate("settlements.cost.totalSettlementValue")}
          >
            <DataText
              value={
                <Currency
                  showCurrency={false}
                  value={calcTotal(convertListCostByMoney, "settlementValue")}
                />
              }
            />
          </Descriptions.Item>

          <Descriptions.Item
            labelStyle={{ fontWeight: 500 }}
            label={translate("settlements.cost.totalEstimateValue")}
          >
            <DataText
              value={
                isEmpty(
                  dataForCreate?.totalEstimatedByCash ??
                    initialValues?.totalEstimatedByCash
                ) ? (
                  "-"
                ) : (
                  <Currency
                    showCurrency={false}
                    className="blue"
                    value={
                      dataForCreate?.totalEstimatedByCash ??
                      initialValues?.totalEstimatedByCash
                    }
                  />
                )
              }
            />
          </Descriptions.Item>
        </Descriptions>
      </Panel>
    </Collapse>
  );
});

export default CostByMoney;
