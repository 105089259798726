import { IResourceComponentsProps, useOne } from "@pankod/refine-core";
import CSpin from "components/CSpin";
import { API_PATH } from "configs/path";
import { FC, memo, useContext, } from "react";
import {
  CreateSettlementsContractContext,
} from "..";
import DetailContractNumber from "../../components/detailContractNumber";
import "./styles.less";

// Function Component
const EstimateInfo: FC<IResourceComponentsProps> = memo((props) => {
  const {
    planState: [plansState, setPlansState],
    isLoadingFetchFinancial,
    isEdit,
  } = useContext(CreateSettlementsContractContext);
  
  // Set planItems
  useOne({
    resource: API_PATH.settlementContractsFinancialMasterData,
    id: "",
    successNotification(data: any) {
      setPlansState(data?.data);
      return {
        message: `Successfully fetched.`,
        description: "Success with no errors",
        type: "success",
      };
    },
    queryOptions: {
      enabled: isEdit ? false : true,
    },
  });

  return (
    <>
      <DetailContractNumber isShowInfo={false}/>
      <CSpin isLoading={isLoadingFetchFinancial} />
    </>
  );
});

export default EstimateInfo;
