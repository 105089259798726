import { Spin, useForm } from "@pankod/refine-antd";
import { IResourceComponentsProps, useNavigation } from "@pankod/refine-core";
import { useLocation } from "@pankod/refine-react-router-v6";
import { ApprovalStatus } from "api/enums";
import { PATH } from "configs/path";
import { IPayment } from "interfaces";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import FormPayment from "./components/form";
import { LoadingIndicator, PageLoading } from "components/layout/pageLoading";

interface IPaymentProps {
  objectData: IPayment;
}
export const PaymentCreate: React.FC<IResourceComponentsProps> = (props) => {
  const { id } = useParams();
  const { form, formProps, saveButtonProps, queryResult,formLoading } = useForm<IPaymentProps>({
    resource: PATH.payments,
    id: id,
  });
  const resourcePath = PATH.payments;
  const { push, list, show } = useNavigation();
  const record: IPayment | undefined = queryResult?.data?.data.objectData;
  const location = useLocation();
  
  const isDone = record?.status === ApprovalStatus.Approved && !location.pathname.includes("clone");

  useEffect(() => {
    if (isDone) {
      push("/payments/show/" + record?.id);
    }
  }, [isDone]);
  
  useEffect(() => {
    form.setFieldsValue({
      ...record,
      ...record?.bank,
      caseId: record?.case?.id,
      advancePaymentId: record?.advancePayment?.id,
      employeeId: record?.employee?.id,
      supplierId: record?.supplier?.id,
      listCosts: record?.refundPaymentListCosts?.map((item) => ({
          money: item.money,
          costName: item?.cost?.name,
          costId: item?.cost?.id,
          realMoney: item.realMoney,
          costCode: item?.cost?.code,
          note: item.note,
      })),
    });
    
  }, [form, formProps, record]);

  return (
    <>
    { formLoading && <LoadingIndicator isLoading={formLoading}/>}
    <FormPayment
      form={form}
      formProps={formProps}
      record={record}
      onCancel={() => list(resourcePath)}
      onSubmitSuccess={(id) => show(resourcePath, id || "")}
      saveButtonProps={saveButtonProps}
    />
     </>
  );
};
