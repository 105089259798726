import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import EndPoints from "configs/api";
import {
  SuccessResponse,
  MeResponse,
  ErrorResponse,
  LoginResponse,
  LoginRequest,
  QueryParams,
  ResetPasswordRequest,
  ForgotPasswordRequest,
  ChangePasswordRequest,
  ExternalLoginRequest,
  InitByExternalAccountRequest,
  ISalePipelineExportFile,
} from "./types";

import {
  TOKEN_KEY,
  EXPIRED_AT,
  ROLE_USER,
  USER as CURRENT_USER,
  TENANT_ID,
  USER_EMPLOYEE,
} from "configs/storage";

import { IFeature, IFeaturePermissionList, IRolePermission } from "interfaces";
import { API_PATH, getApiPath, PATH } from "configs/path";
import { Modal } from "@pankod/refine-antd";
import i18n from "i18n";
import { ContentModal } from "./common";
import { ServiceProduct } from "interfaces/ServiceRequest";
import { EstimatePolicy } from "interfaces/InternalEstimate";
import { DataForCreate as SettlementDataForCreate } from "interfaces/Settlement";

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, //process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

apiInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = localStorage.getItem(TOKEN_KEY);
  const tenantId = localStorage.getItem(TENANT_ID) || "";
  const employee = localStorage.getItem(USER_EMPLOYEE) || "";
  const lang = localStorage.getItem("i18nextLng") || "vi";
  const employeeData = employee ? JSON.parse(employee) : {};

  if (token) {
    const headers = {
      Authorization: `Bearer ${token}`,
      TenantId: tenantId,
      DepartmentId: employeeData?.department?.id,
      "Accept-Language": lang,
    };
    config.headers = { ...config.headers, ...headers };
  }

  return config;
});

const handleSuccessResponse = (response: AxiosResponse): SuccessResponse => {
  return response.data;
};

const handleErrorResponse = (error: AxiosError): ErrorResponse => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (error.response?.status === 401) {
      setTimeout(() => {
        const title = i18n.t("sessionExpired");
        const content = ContentModal();
        const okText = i18n.t("pages.changePassword.successButton");
        Modal.destroyAll();
        Modal.confirm({
          title: title,
          content: content,
          okText: okText,
          cancelButtonProps: {
            hidden: true,
          },
          okButtonProps: {
            onClick: () => {
              window.location.replace("/login");
            },
          },
        });

        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(USER_EMPLOYEE);
        localStorage.removeItem(ROLE_USER);
        localStorage.removeItem(EXPIRED_AT);
        localStorage.removeItem(CURRENT_USER);
      }, 300);
    }
    throw {
      errorCode: error.response?.data?.metaData.errorCode,
      message: error.response.data?.metaData.message,
      httpErrorCode: error.response?.status,
      errors: error.response?.data?.metaData.errors,
    };
  }
  if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
  } else {
    // Something happened in setting up the request that triggered an Error
  }

  throw Error();
};

const sendPostRequest = async (endPoint: string, data?: any, config = {}) => {
  return await apiInstance
    .post(endPoint, data, config)
    .then(handleSuccessResponse)
    .catch(handleErrorResponse);
};

const sendPutRequest = async (endPoint: string, data?: any, config?: any) => {
  return await apiInstance
    .put(endPoint, data, config)
    .then(handleSuccessResponse)
    .catch(handleErrorResponse);
};

// Send Request Delete

const sendDeleteRequest = async (endPoint: string, data?: any) => {
  return await apiInstance
    .delete(endPoint, data)
    .then(handleSuccessResponse)
    .catch(handleErrorResponse);
};

const sendGetRequest = async (endPoint: string, params?: any, config = {}) => {
  return await apiInstance
    .get(endPoint, { params, ...config })
    .then(handleSuccessResponse)
    .catch((error) => {
      // if (error.response?.status === 403) {
      //   window.location.replace(`/${PATH.noPermissionPage}`);
      //   return;
      // }
      handleErrorResponse(error);
    });
};

export const login = async (request: LoginRequest): Promise<LoginResponse> => {
  const result = (await sendPostRequest(
    EndPoints.login(),
    request
  )) as LoginResponse;
  return result;
};
export const externalLogin = async (
  request: ExternalLoginRequest
): Promise<LoginResponse> => {
  const result = (await sendPostRequest(
    EndPoints.externalLogin(),
    request
  )) as LoginResponse;
  return result;
};

export const logout = async (): Promise<any> => {
  const result = await sendPostRequest(EndPoints.logout());
  return result;
};

export const fetchMe = async (): Promise<MeResponse> => {
  const result = (await sendGetRequest(EndPoints.me())) as MeResponse;
  return result;
};

export const getTenantPermission = async (): Promise<MeResponse> => {
  const result = (await sendGetRequest(
    EndPoints.getTenantPermission()
  )) as MeResponse;
  return result;
};

export const changePassword = async (
  data: ChangePasswordRequest
): Promise<SuccessResponse> => {
  const result = (await sendPutRequest(
    EndPoints.changePassword(),
    data
  )) as SuccessResponse;
  return result;
};

export const forgotPassword = async (
  data: ForgotPasswordRequest
): Promise<SuccessResponse> => {
  const result = (await sendPostRequest(
    EndPoints.forgotPassword(),
    data
  )) as SuccessResponse;
  return result;
};

export const resetPassword = async (
  data: ResetPasswordRequest
): Promise<SuccessResponse> => {
  const result = (await sendPutRequest(
    EndPoints.resetPassword(),
    data
  )) as SuccessResponse;
  return result;
};

export const initByExternalAccount = async (
  data: InitByExternalAccountRequest
): Promise<SuccessResponse> => {
  const result = (await sendPutRequest(
    EndPoints.initByExternalAccount(),
    data
  )) as SuccessResponse;
  return result;
};

export const fetchList = async (
  apiPath: string,
  query: QueryParams
): Promise<SuccessResponse> => {
  const result = (await sendGetRequest(
    EndPoints.list(apiPath),
    query
  )) as SuccessResponse;
  return result;
};

export const fetchCreate = async (
  apiPath: string,
  data: any
): Promise<SuccessResponse> => {
  const result = (await sendPostRequest(
    EndPoints.create(apiPath),
    data
  )) as SuccessResponse;
  return result;
};

export const fetchUpdate = async (
  apiPath: string,
  id: string,
  data: any,
  config?: any
): Promise<SuccessResponse> => {
  const result = (await sendPutRequest(
    EndPoints.update(apiPath, id),
    data,
    config
  )) as SuccessResponse;
  return result;
};

export const fetchDelete = async (
  apiPath: string,
  id: string,
  data?: any
): Promise<SuccessResponse> => {
  let url = data ? EndPoints.delete(apiPath): EndPoints.delete(apiPath, id);
  const result = (await sendDeleteRequest(
    url,
    data
  )) as SuccessResponse;
  return result;
};

export const fetchDetail = async (
  apiPath: string,
  userId?: string
): Promise<SuccessResponse> => {
  const result = (await sendGetRequest(
    EndPoints.detail(apiPath, userId)
  )) as SuccessResponse;
  return result;
};

export const checkInviteValid = async (data: any): Promise<SuccessResponse> => {
  const result = (await sendPostRequest(
    EndPoints.users("invite-active-user"),
    data
  )) as SuccessResponse;
  return result;
};

export const validatePasswordToken = async (
  data: any
): Promise<SuccessResponse> => {
  const result = (await sendPostRequest(
    EndPoints.users("validation-token"),
    data
  )) as SuccessResponse;
  return result;
};

export const createUser = async (data: any): Promise<SuccessResponse> => {
  const result = (await sendPostRequest(
    EndPoints.createUser(),
    data
  )) as SuccessResponse;
  return result;
};

export const userResetPassword = async (
  data: any
): Promise<SuccessResponse> => {
  const result = (await sendPutRequest(
    EndPoints.users("reset-password"),
    data
  )) as SuccessResponse;
  return result;
};

export const userActive = async (data: any): Promise<SuccessResponse> => {
  const result = (await sendPutRequest(
    EndPoints.users("active-user"),
    data
  )) as SuccessResponse;
  return result;
};

export const userResendEmail = async (data: any): Promise<SuccessResponse> => {
  const result = (await sendPutRequest(
    EndPoints.users("resend-active"),
    data
  )) as SuccessResponse;
  return result;
};
export const updateStatusUser = async (data: any): Promise<SuccessResponse> => {
  const result = (await sendPutRequest(
    EndPoints.users("update-status"),
    data
  )) as SuccessResponse;
  return result;
};

export const fetchTenants = async (
  apiPath: string,
  query?: QueryParams
): Promise<SuccessResponse> => {
  const result = (await sendGetRequest(
    EndPoints.tenants(apiPath),
    query
  )) as SuccessResponse;
  return result;
};

export const getFeatures = async (
  apiPath: string
): Promise<SuccessResponse<IFeature[]>> => {
  const result = (await sendGetRequest(
    EndPoints.tenants(apiPath)
  )) as SuccessResponse<IFeature[]>;
  return result;
};

export const postRolePermissions = async (
  apiPath: string,
  data: IRolePermission
): Promise<SuccessResponse> => {
  const result = (await sendPostRequest(
    getApiPath(apiPath),
    data
  )) as SuccessResponse;
  return result;
};

export const getLocationList = async (
  apiPath: string,
  query?: QueryParams
): Promise<SuccessResponse> => {
  const result = (await sendGetRequest(
    EndPoints.getLocationList(apiPath),
    query
  )) as SuccessResponse;
  return result;
};

export const getUserTenantMisRoles = async (
  id: string
): Promise<SuccessResponse> => {
  const result = (await sendGetRequest(
    EndPoints.getUserTenantMisRoles(id)
  )) as SuccessResponse;
  return result;
};

export const getRolePermissions = async (
  roleId: any
): Promise<SuccessResponse<IFeaturePermissionList[]>> => {
  const result = (await sendGetRequest(
    `${getApiPath(PATH.rolePermissions)}/get-by-role/${roleId}`
  )) as SuccessResponse<IFeaturePermissionList[]>;
  return result;
};

export const deleteCustomerExploitConfigsValidation = async (
  id: string
): Promise<boolean> => {
  const url = `api/CustomerExploitConfigs/check-to-delete?id=${id}`;
  const result = (await sendGetRequest(url)) as any;
  return result;
};

export const downloadFile = async (endpoint: string, fileId: string) => {
  const res = await sendGetRequest(endpoint, { fileId });
  return res;
};

export const salePiepelineExportFile = async (
  endpoint: string,
  params: ISalePipelineExportFile
) => {
  const res = await sendGetRequest(endpoint, params);
  return res;
};

export const getProductsForBusinessPlan = async (endpoint: string) => {
  const res = await sendGetRequest(endpoint);
  if (!res || !res.data) throw new Error("Nope");
  return res;
};

export const getProductsForQuotation = async (endpoint: string) => {
  const res = await sendGetRequest(endpoint);
  if (!res || !res.data) throw new Error("Nope");
  return res;
};

export const exportPDFQuotation = async (endpoint: string) => {
  const res = await sendGetRequest(endpoint, undefined, {
    responseType: "arraybuffer",
    responseEncoding: "binary",
    headers: { "Content-Type": "application/pdf" },
  });
  return res;
};

export const uploadFileApi = async (
  endpoint: string,
  data: FormData,
  caseId: string
) => {
  const res = (await sendPostRequest(endpoint, data, {
    data: { caseId },
  })) as SuccessResponse;

  return res;
};

export const getProductsDefaultForContract = async (endpoint: string) => {
  const res = await sendGetRequest(endpoint);
  if (!res || !res.data) throw new Error("Nope");
  return res;
};

export const getLinkPrewviewFile = async (
  endpoint: string,
  fileUrl: string
) => {
  const res = (await sendGetRequest(endpoint, { fileUrl })) as SuccessResponse;
  if (!res || !res.data) throw new Error("Nope");
  return res;
};

export const getProductDefaultForServiceRequest = async (endpoint: string) => {
  const res = (await sendGetRequest(endpoint)) as SuccessResponse<
    ServiceProduct[]
  >;
  if (!res || !res.data) throw new Error("Nope");
  return res;
};

export const getInternalEstimateProductFromConsult = async (
  endpoint: string,
  referenceRequestId: string
) => {
  const res = (await sendGetRequest(endpoint, {
    referenceRequestId,
  })) as SuccessResponse<any[]>;
  if (!res || !res.data) throw new Error("Nope");
  return res;
};

export const getInternalEstimateProductFromBusinessPlan = async (
  endpoint: string,
  caseId: string
) => {
  const res = (await sendGetRequest(endpoint, {
    caseId,
  })) as SuccessResponse<any[]>;
  if (!res || !res.data) throw new Error("Nope");
  return res;
};

export const getInternalEstimatePolicy = async (params: {
  products: { productId: string; orderSort: number }[];
  internalEstimateType: string;
}) => {
  const res = (await sendPostRequest(
    API_PATH.internalEstimatesPolicyDropdownlist,
    params
  )) as SuccessResponse<EstimatePolicy>;
  if (!res || !res.data) throw new Error("Nope");
  return res;
};

export const getBusinessPlanFinacialIndicator = async (data: {
  caseId: string;
  products: any[];
  indicators: any[];
}) => {
  const res = (await sendPostRequest(
    API_PATH.businessPlanFinacialIndicator,
    data
  )) as SuccessResponse<{ errors: any[]; financialIndicator: any[] }>;
  if (!res || !res.data) throw new Error("Nope");
  return res;
};

export const getProductsDefault = async (endpoint: string) => {
  const res = await sendGetRequest(endpoint);
  if (!res || !res.data) throw new Error("Nope");
  return res;
};

export const getContractEstimatedFinacialIndicator = async (data: {
  caseId: string;
  products: any[];
  indicators: any[];
}) => {
  const res = (await sendPostRequest(
    API_PATH.contractEstimatedsFinancialIndicator,
    data
  )) as SuccessResponse<{ errors: any[]; financialIndicator: any[] }>;
  if (!res || !res.data) throw new Error("Nope");
  return res;
};

export const updateSettlementContractFinancialIndicator = async (data: {
  caseId: string;
  indicators: any[];
}) => {
  const res = (await sendPostRequest(
    API_PATH.settlementContractsFinancialIndicator,
    data
  )) as SuccessResponse<{ errors: any[]; financialIndicator: any[] }>;
  if (!res || !res.data) throw new Error("Error.updateSettlementContractFinancialIndicator");
  return res;
};
export const getDataForCreateSettlement = async (
  endpoint: string,
  caseId: string
) => {
  const res = (await sendGetRequest(endpoint, {
    caseId,
  })) as SuccessResponse<SettlementDataForCreate>;
  if (!res || !res.data) throw new Error("Nope");
  return res;
};

export const sendSettlementDefaultData = async (endpoint: string, body: any) => {
  const res = (await sendPostRequest(endpoint, body)) as SuccessResponse<SettlementDataForCreate>;
  if (!res || !res.data) throw new Error();
  return res;
}