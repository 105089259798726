import { SyncOutlined } from "@ant-design/icons";
import { Button, Form, Typography, notification } from "@pankod/refine-antd";
import { useTranslate } from '@pankod/refine-core';
import { CurrencyType } from "api/enums";
import { Currency, generateCurrency } from "components/Currency";
import { MoneyInput } from 'components/MoneyInput';
import { TPlanItem } from "interfaces/SettlementContract";
import { cloneDeep } from 'lodash';
import { FC, memo, useContext, useMemo } from "react";
import { formatNumber, isEmpty } from "utils/commons";
import { CreateSettlementsContractContext, IFormCreateSettlementsContract } from '../create';
import { updateSettlementContractFinancialIndicator } from 'api';
import { IPlanItem } from "interfaces/PlanItem";
import { ONE_THOUSAND_TRILLION } from "configs/constants";

const { useWatch, useFormInstance } = Form;
interface TableShowProps {
  plans?: TPlanItem[];
  showButtonGetSettlementNumber?: boolean;
  isShowInfo: boolean;
}

const { Text } = Typography;

let timeoutFetchTableCalc: any;
const DetailContractNumber: FC<TableShowProps> = memo(({ plans, showButtonGetSettlementNumber, isShowInfo }) => {
  const {
    planState: [plansState, setPlans],
  } = useContext(CreateSettlementsContractContext);
  const form = useFormInstance<IFormCreateSettlementsContract>();
  const caseId = useWatch("caseId", form);
  const translate = useTranslate();
  const debounceSendAPI = (newData: IPlanItem[]) => {
    if (timeoutFetchTableCalc) clearTimeout(timeoutFetchTableCalc);
    timeoutFetchTableCalc = setTimeout(
      () => {
        const indicatorsData = newData.map((item) => {
          return {
            code: item.code,
            value: item.value,
          };
        });
        updateSettlementContractFinancialIndicator({caseId: caseId, indicators: indicatorsData}).then((res) => {
          const data = res?.data?.financialIndicator as IPlanItem[];
          const errors = res?.data?.errors || [];
      
          const dataUpdate = data.map((item) => {
            const error = errors.find((error) => item.code === error.field);
            const statusRequired = newData.find((old) => item.code === old.code);
      
            return {
              ...item,
              errorMessage: error.message,
              isRequired: statusRequired?.isRequired ?? item.isRequired,
            };
          });
      
          setPlans(dataUpdate);
        })
        .catch((error) => {
          notification.error({
            message: error.message,
          });
        });
      },
      1500
    );
  }
  const getEstimateValueError = (data: TPlanItem) => {
    const { value, isPersonInput } = data;
    if (data.isRequired && isEmpty(value) && isPersonInput && !isShowInfo)
      return translate("errors.ER005");
    if (
      isPersonInput &&
      !isEmpty(value) &&
      (value > ONE_THOUSAND_TRILLION || value < 0)
    ) {
      return translate(`errors.ER0204`, {
        min: 0,
        max: formatNumber(ONE_THOUSAND_TRILLION),
      });
    }
    return "";
  };
  const currency = useMemo(() => generateCurrency(CurrencyType.VND), []);
  const onChangeCase = (index: number) => (value: any) => {
    const temp = cloneDeep(plansState);
    if (value === temp[index].value) return;

    temp[index].value = value;
    setPlans(temp);
    debounceSendAPI(temp);
  }
  const plansData = plansState?.length > 0 ? plansState : plans;
  const renderPlans = () => {
    return plansData?.map((d, index) => {
      const { isStrong } = d;

      return (
        <div className={`row col-active`} key={d.code}>
          <div className={`col ${isStrong ? "font-bold" : ""}`}>{d.code}</div>
          <div className={`col ${isStrong ? "font-bold" : ""}`}>{d.name}</div>
          <div className={`col text-right`}>
            {d.isPersonInput && !isShowInfo ? (<MoneyInput
                currencyType={CurrencyType.VND}
                value={isEmpty(d.value) ? "" : d.value}
                onChange={onChangeCase(index)}
                allowDecimal
              />) :
              (<Currency
                showCurrency={false}
                value={d.value}
                customValueShow={formatNumber}
                strong={isStrong}
              />)}
              <div className="ant-form-item-explain-error">
              {d.errorMessage || getEstimateValueError(d) }
            </div>
          </div>
          <div className="col text-right" style={{ whiteSpace: "nowrap" }}>

            <Currency
              showCurrency={false}
              value={d.contractEstimatedValue}
              customValueShow={formatNumber}
              strong={isStrong}
            />

          </div>
          <div className={`col text-right ${isStrong ? "font-bold" : ""}`}>
            <Currency
              showCurrency={false}
              value={d.margin}
              customValueShow={formatNumber}
              strong={isStrong}
            />
          </div>
          <div className={`col text-right ${isStrong ? "font-bold" : ""}`}>
            <Currency
              showCurrency={false}
              value={d.businessPlanValue}
              customValueShow={formatNumber}
              strong={isStrong}
            />
          </div>
          <div
            className="col text-right"
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <Currency
              value={d?.percentage * 100}
              showCurrency={false}
              customValueShow={(value) => `${formatNumber(value || 0, 3)}%`}
              strong={isStrong}
              hidden={index === 0}
            />
          </div>
          <div
            className={`col ${isStrong ? "font-bold" : ""}`}
            dangerouslySetInnerHTML={{ __html: d?.note }}
          ></div>
        </div>
      );
    });
  };

  return (
    <>
      <div className="flex justify-end" hidden={showButtonGetSettlementNumber == false}>
        <Button type="primary" onClick={() => {
          // TODO: call api get settlement number
          notification.warning({
            message: "Tính năng đang phát triển ... Đừng bấm nữa",
          });
        }} loading={false} icon={<SyncOutlined />}>Get number settlement contracts</Button>
      </div>
      <div className="flex items-center justify-end">
        <Text>
          {translate("products.fields.unit")}:{" "}
          <Text style={{ color: currency.unitColor, fontWeight: "bold" }}>
            {currency.unit}
          </Text>
        </Text>
      </div>
      <div style={{ overflow: "auto", position: "relative" }}>
        <section>
          <header>
            <div style={{ width: 50 }} className="col col-head">
              {translate("common.no")}
            </div>
            <div className="col col-head" style={{ minWidth: 250 }}>
              Chỉ tiêu
            </div>
            <div className="col col-head text-right" style={{ minWidth: 220 }}>
              Số quyết toán
            </div>
            <div className="col col-head text-right" style={{ minWidth: 220 }}>
              Số Dự toán
            </div>
            <div className="col col-head text-right" style={{ minWidth: 220 }}>
              Chệnh lệch
            </div>
            <div className="col col-head text-right" style={{ minWidth: 220 }}>
              PAKD
            </div>
            <div className="col col-head text-right" style={{ minWidth: 100 }}>
              Tỷ lệ
            </div>
            <div className="col col-head" style={{ minWidth: 200 }}>
              Ghi chú
            </div>
          </header>
          {renderPlans()}
        </section>
      </div>
    </>
  );
});

export default DetailContractNumber;
