import { FC, useState } from "react";
import { CurrencyDisplayType, CurrencyType } from "api/enums";
import CurrencyInput, { CurrencyInputProps } from "react-currency-input-field";

interface MoneyInputProps {
  currencyType: CurrencyType;
  displayType?: CurrencyDisplayType;
  allowNegative?: boolean;
  value?: any;
  isValid?: boolean;
  allowClear?: boolean;
  min?: number;
  max?: number;
  align?: "right" | "left";
  groupSeparator?: string;
  decimalSeparator?: string;
  allowDecimal?: boolean;
  decimalLength?: number;
}

export const MoneyInput: FC<CurrencyInputProps & MoneyInputProps> = (props) => {
  const {
    currencyType,
    displayType = CurrencyDisplayType.INPUT,
    onChange,
    allowNegative = false,
    value,
    placeholder,
    isValid = true,
    allowClear = true,
    disabled,
    min = allowNegative ? Number.MIN_VALUE : 0,
    max = Number.MAX_VALUE,
    align = "right",
    groupSeparator = ",",
    decimalSeparator = ".",
    allowDecimal = false,
    decimalLength = 3,
    ...otherProps
  } = props;

  const [displayValue, setDisplayValue] = useState<any>(value || "");

  return (
    <CurrencyInput
      {...otherProps}
      allowDecimals={allowDecimal}
      className={`ant-input money-input text-${align}`}
      disabled={disabled}
      placeholder={placeholder}
      value={onChange ? value : displayValue}
      decimalsLimit={allowDecimal ? decimalLength : 0}
      groupSeparator={groupSeparator}
      decimalSeparator={decimalSeparator}
      allowNegativeValue={allowNegative}
      onKeyPress={(e: any) => {
        if (e && !allowNegative && e.key === "-") {
          e.preventDefault();
        }
      }}
      onValueChange={(value: any, name, values: any) => {
        if (value == props.value) return;
        setDisplayValue(value);

        if (onChange) {
          onChange(value);
        }
      }}
    />
  );
};
