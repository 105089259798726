import React from "react";
import {
  Icons,
  List,
  Table,
  TextField,
  Space,
  Tag,
  Button,
  Radio,
  getDefaultSortOrder,
  EditButton,
  DeleteButton,
} from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useTranslate,
  useTable,
  useNavigation,
} from "@pankod/refine-core";
import { useNavigate } from "react-router-dom";
import { EmptyData, SearchBox, ButtonTooltip } from "components";
import { formatISODateTimeToView } from "utils/commons";
import { ApprovalProcedureStatus } from "api/enums";
import { IWorkflowData } from "interfaces";
import { PATH } from "configs/path";
import { isEmpty } from "lodash";
import usePermissions from "hooks/permission";

export const WorkflowList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const navigate = useNavigate();
  const [isSearch, setIsSearch] = React.useState<boolean>(false);
  const { edit, show, create } = useNavigation();
  const {
    checkEdit,
    checkCreate,
    checkCreateHaveHelperText,
    checkEditHaveHelperText,
  } = usePermissions();

  const {
    tableQueryResult: { data: dataTable },
    sorter,
    setSorter,
    pageSize,
    setPageSize,
    current,
    setCurrent,
    setFilters,
    filters,
  } = useTable<any>({
    initialCurrent: 1,
    initialSorter: [
      {
        field: "createdDate",
        order: "desc",
      },
    ],
  });

  const onSearch = (value: string) => {
    setCurrent(1)
    setFilters([
      {
        field: "q",
        operator: "containss",
        value: value,
      },
    ]);
  };

  React.useEffect(() => {
    setCurrent(1);
    if (filters && filters.length > 0) {
      setIsSearch(true);
    } else {
      setIsSearch(false);
    }
  }, [filters]);

  const renderStatus = (value: number) => {
    switch (value) {
      case ApprovalProcedureStatus.Published:
        return (
          <Tag color="success">{t("workflows.fields.status.published")}</Tag>
        );

      case ApprovalProcedureStatus.Noset:
        return <Tag color="red">{t("workflows.fields.status.noset")}</Tag>;

      case ApprovalProcedureStatus.Draft:
        return <Tag color="warning">{t("workflows.fields.status.draft")}</Tag>;

      default:
        return (
          <Tag color="#F0F0F0" style={{ color: "#8C8C8C" }}>
            {t("workflows.fields.status.unpublished")}
          </Tag>
        );
    }
  };

  return (
    <List
      headerButtons={
        <ButtonTooltip
          type="primary"
          icon={<Icons.PlusOutlined />}
          onClick={() => create(PATH.workflows)}
          {...checkCreateHaveHelperText(PATH.workflows)}
        >
          {t("workflows.actions.add")}
        </ButtonTooltip>
      }
      breadcrumb={null}
    >
      <div className="list-content">
        <SearchBox
          placeholder={t("workflows.searchPlaceHolder")}
          onSearch={onSearch}
        />
        <Radio.Group
          defaultValue={4}
          buttonStyle="solid"
          onChange={(e) => {
            setFilters([
              {
                field: "status",
                operator: "eq",
                value: e?.target?.value === 4 ? "" : e?.target?.value,
              },
            ]);
          }}
          style={{ display: "block", marginBottom: 16 }}
        >
          <Radio.Button value={4}>
            {t("workflows.fields.status.all")}
          </Radio.Button>
          <Radio.Button value={2}>
            {t("workflows.fields.status.published")}
          </Radio.Button>
          <Radio.Button value={3}>
            {t("workflows.fields.status.unpublished")}
          </Radio.Button>
          <Radio.Button value={1}>
            {t("workflows.fields.status.draft")}
          </Radio.Button>
          <Radio.Button value={0}>
            {t("workflows.fields.status.nosetFull")}
          </Radio.Button>
        </Radio.Group>
        <Table
          dataSource={dataTable?.data}
          rowKey="id"
          locale={{
            emptyText: (
              <EmptyData text={isSearch ? t("common.noDataSearch") : ""} />
            ),
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                show(PATH.workflows, record.id);
              },
            };
          }}
          pagination={{
            pageSize: pageSize,
            current: current,
            total: dataTable?.total,
            onChange: (cur, size) => {
              setCurrent(cur);
              setPageSize(size);
              document.body.scrollTop = 0; // For Safari
              document.documentElement.scrollTop = 0;
            },
            locale: {
              items_per_page: t("common.pageNumber"),
            },
            showTotal: (total) => (
              <p>{t("common.totalPage", { total: total })}</p>
            ),
          }}
          onChange={(_, __, s) => {
            const newSort = s as any;
            if (isEmpty(newSort)) {
              setSorter([
                {
                  field: "createdDate",
                  order: "desc",
                },
              ]);
              return;
            }
            setSorter([
              {
                field:
                  newSort?.field === "department"
                    ? "department.name"
                    : newSort?.field,
                order: newSort?.order,
              },
            ]);
          }}
          showSorterTooltip={false}
          scroll={{ x: "2000px" }}
        >
          <Table.Column
            key="id"
            dataIndex="id"
            title={t("common.no")}
            render={(_, __, index) => (current - 1) * pageSize + index + 1}
            width={70}
          />
          <Table.Column
            key="code"
            dataIndex="code"
            title={t("workflows.fields.code")}
          />
          <Table.Column
            width={220}
            key="name"
            dataIndex="name"
            title={t("workflows.fields.fullName")}
            defaultSortOrder={getDefaultSortOrder("name", sorter)}
            sorter
          />
          <Table.Column
            key="status"
            dataIndex="status"
            title={t("employees.fields.status.title")}
            render={(value) => renderStatus(value)}
            defaultSortOrder={getDefaultSortOrder("status", sorter)}
            sorter
          />
          <Table.Column
            key="featureName"
            dataIndex="featureName"
            title={t("workflows.fields.feature")}
            defaultSortOrder={getDefaultSortOrder("featureName", sorter)}
            sorter
          />
          <Table.Column
            key="appliedFromDate"
            dataIndex="appliedFromDate"
            title={t("workflows.fields.appliedFromDate")}
            render={(value) => (
              <TextField
                value={formatISODateTimeToView(value as string, "DD/MM/YYYY")}
              />
            )}
            width={200}
            defaultSortOrder={getDefaultSortOrder("appliedFromDate", sorter)}
            sorter
          />
          <Table.Column
            key="appliedToDate"
            dataIndex="appliedToDate"
            title={t("workflows.fields.appliedToDate")}
            render={(value) => (
              <TextField
                value={formatISODateTimeToView(value as string, "DD/MM/YYYY")}
              />
            )}
            width={200}
            defaultSortOrder={getDefaultSortOrder("appliedToDate", sorter)}
            sorter
          />
          <Table.Column
            key="updatedName"
            dataIndex="updatedName"
            title={t("workflows.fields.updatedName")}
            render={(value) => <TextField className="primary" value={value} />}
            width={220}
          />
          <Table.Column
            key="updatedDate"
            dataIndex="updatedDate"
            title={t("workflows.fields.updatedDate")}
            render={(value) => (
              <TextField
                value={formatISODateTimeToView(
                  value as string,
                  "DD/MM/YYYY HH:mm:ss"
                )}
              />
            )}
            width={200}
            defaultSortOrder={getDefaultSortOrder("updatedDate", sorter)}
            sorter
          />
          <Table.Column
            key="createdName"
            dataIndex="createdName"
            title={t("workflows.fields.createdName")}
            render={(value) => <TextField className="primary" value={value} />}
            width={220}
          />
          <Table.Column<IWorkflowData>
            title={t("table.actions")}
            dataIndex="actions"
            align="center"
            fixed="right"
            width={170}
            render={(_, record) => {
              const disabledCreateWorkflowsDiagrams =
                record?.status === ApprovalProcedureStatus.Noset &&
                !checkCreate(PATH.workflowsDiagrams);
              const disabledEditWorkflowsDiagrams =
                !checkEdit(PATH.workflowsDiagrams) || !!record?.appliedRecord;
              return (
                <Space>
                  {record?.status !== ApprovalProcedureStatus.Published && (
                    <ButtonTooltip
                      type="text"
                      icon={<Icons.EditOutlined />}
                      className="gray"
                      onClick={(e) => {
                        e.stopPropagation();
                        edit(PATH.workflows, record.id);
                      }}
                      {...checkEditHaveHelperText(PATH.workflows)}
                    />
                  )}
                  {record?.status === ApprovalProcedureStatus.Noset && (
                    <>
                      <div className="line-between-action"></div>
                      <ButtonTooltip
                        type="text"
                        icon={<Icons.SettingOutlined />}
                        className="gray"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(
                            `${PATH.workflowsDiagrams}/create/${record.id}`
                          );
                        }}
                        disabled={disabledCreateWorkflowsDiagrams}
                        helpertext={
                          disabledCreateWorkflowsDiagrams
                            ? "common.noHasPermission"
                            : undefined
                        }
                      />
                    </>
                  )}
                  {record?.status !== ApprovalProcedureStatus.Published &&
                    record?.status !== ApprovalProcedureStatus.Noset && (
                      <>
                        <div className="line-between-action"></div>
                        <ButtonTooltip
                          type="text"
                          icon={<Icons.SettingOutlined />}
                          className="gray"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(
                              `${PATH.workflowsDiagrams}/edit/${record.id}`
                            );
                          }}
                          disabled={disabledEditWorkflowsDiagrams}
                          helpertext={
                            disabledEditWorkflowsDiagrams
                              ? "common.noHasPermission"
                              : undefined
                          }
                        />
                      </>
                    )}
                  {record?.status !== ApprovalProcedureStatus.Published && (
                    <>
                      <div className="line-between-action"></div>
                      <DeleteButton
                        hideText
                        type="text"
                        style={{ color: "#8C8C8C" }}
                      />
                    </>
                  )}
                </Space>
              );
            }}
          />
        </Table>
      </div>
    </List>
  );
};
